import * as React from 'react';
const NewPatient = React.lazy(() => import('./new-patient/NewPatient.page'));
const Intake = React.lazy(() => import('./intake/Intake.page'));
const IntakeSample = React.lazy(() => import('./intake/IntakePrototype.page'));
const IntakePin = React.lazy(() => import('./intake/IntakePin.page'));
const IntakePreview = React.lazy(() => import('./intake/IntakePreview.page'));
import {
  BrowserRouter, Routes, Route
} from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { includes } from 'ramda';


const Home = () => {
  return <div>
    Home
  </div>;
};

interface ErrorGuy {
  hasError: boolean;
  type: string;
}

class ErrorBoundary extends React.Component<any, ErrorGuy> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      type: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    const isChunkLoadingError = includes('chunk', error.message);
    return {
      hasError: true,
      type: isChunkLoadingError ? 'chunk' : 'error',
    };
  }
  componentDidCatch(error: any, errorInfo: any) {
    const isChunkLoadingError = includes('chunk', error.message);
    console.log(error.message, errorInfo);
    if (isChunkLoadingError) {
      window.location.reload();
    }
  }
  render() {
    if (this.state.hasError && this.state.type === 'error') {
      return (
        <div style={{
          display: 'flex',
          maxWidth: '520px',
          margin: '0px auto',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%'
        }}>
          <div>
            <h2>Oops... Something went wrong.</h2>
            <p>
              Let us know what happened by using the chat or emailing <a href="mailto:info@sked.life">info@sked.life</a>. Otherwise, give this page a refresh to get back into SKED.
            </p>
          </div>
        </div>
      );
    } else if (this.state.hasError && this.state.type === 'chunk') {
      return (
        <p>There are been an update to the SKED Admin. Auto-reloading...</p>
      );
    }
    return this.props.children;
  }
}

const Complete = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Paper style={{
        width: '300px',
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '20px',
      }}>
        <CheckIcon style={{
          color: 'green',
          fontSize: '50px'
        }} />
        <h1>Thank you!</h1>
        <br />
        Your office has received your form!
      </Paper>
    </div>
  );
};

const Loading = () => <div>Loading...</div>;

export const App = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={Loading}>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Routes>
              <Route path="/new-patient/*" element={<NewPatient />} />
              <Route path="/intake-sample" element={<IntakeSample />} />
              <Route path="/intake-preview" element={<IntakePreview />} />
              <Route path="/intake" element={<Intake />} />
              <Route path="/intake-start/*" element={<IntakePin />} />
              <Route path="/intake-office/*" element={<IntakePin />} />
              <Route path="/intake-complete" element={<Complete />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </React.Suspense>
    </ErrorBoundary>
  );
};
